/*
 * This file acts as a fallback for the branding-specific variables in case they could not be loaded.
 * The content defines which variables should be considered available globally
 * for use inside the whole project as the single source of truth.
 *
 * DO NOT define global variables elsewhere, if they dont belong here, they should most likely be local variables.
 * DO NOT define global variables inconsiderately to avoid polluting the namespace with quickly abandoned variables.
 */

@layer branding-fallback {
    /* COLOR PALETTE */
    :root {
        --ldt-color-grey-300: #f2f2f2;
        --ldt-color-grey-200: #d6d6d6;
        --ldt-color-grey-100: #bababa;
        --ldt-color-grey: #9e9e9e;
        --ldt-color-grey--100: #7f7f7f;
        --ldt-color-grey--200: #606060;
        --ldt-color-grey--300: #444444;
        --ldt-color-grey--400: #282828;
        --ldt-color-grey--500: #0c0c0c;
        --ldt-color-primary-300: #f2f2f2;
        --ldt-color-primary-200: #d6d6d6;
        --ldt-color-primary-100: #bababa;
        --ldt-color-primary: #9e9e9e;
        --ldt-color-primary--100: #7f7f7f;
        --ldt-color-primary--200: #606060;
        --ldt-color-primary--300: #444444;
        --ldt-color-primary--400: #282828;
        --ldt-color-primary--500: #0c0c0c;
        --ldt-color-secondary-300: #f4dbc2;
        --ldt-color-secondary-200: #f5c285;
        --ldt-color-secondary-100: #f0a34f;
        --ldt-color-secondary: #f59e17;
        --ldt-color-secondary--100: #d98517;
        --ldt-color-secondary--200: #b87012;
        --ldt-color-secondary--300: #7a4a0a;
        --ldt-color-secondary--400: #472905;
        --ldt-color-secondary--500: #241203;
        --ldt-color-danger-300: #f5adad;
        --ldt-color-danger-200: #db5e52;
        --ldt-color-danger-100: #c73d29;
        --ldt-color-danger: #ad2905;
        --ldt-color-danger-rgb: 173, 41, 5;
        --ldt-color-danger--100: #8f1a05;
        --ldt-color-danger--200: #780d05;
        --ldt-color-danger--300: #590a03;
        --ldt-color-danger--400: #2b0000;
        --ldt-color-danger--500: #1f0000;
        --ldt-color-success-300: #ccedbf;
        --ldt-color-success-200: #96db7a;
        --ldt-color-success-100: #82d45e;
        --ldt-color-success: #66c745;
        --ldt-color-success--100: #59ad36;
        --ldt-color-success--200: #4a9e26;
        --ldt-color-success--300: #3d7d1f;
        --ldt-color-success--400: #2b5e17;
        --ldt-color-success--500: #143608;
        --ldt-color-light-orange-300: #ffd6a8;
        --ldt-color-light-orange-200: #ffc480;
        --ldt-color-light-orange-100: #ebb275;
        --ldt-color-light-orange: #d99e69;
        --ldt-color-light-orange--100: #c78c5c;
        --ldt-color-light-orange--200: #ad7347;
        --ldt-color-light-orange--300: #996138;
        --ldt-color-light-orange--400: #804d29;
        --ldt-color-light-orange--500: #61361a;
    }

    /* COLOR PALETTE FILTERS */
    :root {
        --ldt-color-grey-filter: invert(100%) brightness(58%);
        --ldt-color-grey--100-filter: invert(100%) brightness(50%);
        --ldt-color-primary-filter: invert(82%) sepia(22%) saturate(7275%) hue-rotate(146deg) brightness(89%) contrast(86%);
        --ldt-color-primary--500-filter: invert(11%) sepia(22%) saturate(1342%) hue-rotate(131deg) brightness(97%) contrast(101%);
        --ldt-color-secondary-filter: invert(72%) sepia(21%) saturate(3901%) hue-rotate(350deg) brightness(99%) contrast(94%);
        --ldt-color-danger-filter: brightness(0) saturate(100%) invert(10%) sepia(100%) saturate(5640%) hue-rotate(31deg) brightness(101%) contrast(96%);
        --ldt-color-success-filter: invert(63%) sepia(94%) saturate(332%) hue-rotate(59deg) brightness(92%) contrast(86%);
        --ldt-color-font-primary-filter: var(--ldt-color-primary--500-filter);
    }

    /* COLORS */
    :root {
        --ldt-color-negative: white;
        --ldt-color-negative-highlight: var(--ldt-color-primary-300);
        --ldt-color-negative-highlight-disabled: var(--ldt-color-grey-300);
        --ldt-color-font-primary: var(--ldt-color-primary--500);
        --ldt-color-font-secondary: var(--ldt-color-grey--500);
    }

    /* FONTS */
    :root {
        --ldt-font-primary: "IBM Plex Sans";
        --ldt-font-secondary: "Poppins";
    }

    /* BUTTONS */
    :root {
        --ldt-button-border-width: 0.1em;
        --ldt-button-border-radius: 0.7em;
        --ldt-button-font-family: var(--ldt-font-primary);
        --ldt-button-font-weight: 500;
        --ldt-button-font-size: 1em;
        --ldt-button-big-font-size: 1em;
        --ldt-button-padding: var(--ldt-button-padding-ver) var(--ldt-button-padding-hor);
        --ldt-button-padding-ver: 0.5em;
        --ldt-button-padding-hor: 1em;
        --ldt-button-dirty-background-color: var(--ldt-color-primary-300);
        --ldt-button-big-padding: var(--ldt-button-big-padding-ver) var(--ldt-button-big-padding-hor);
        --ldt-button-big-padding-ver: 0.8em;
        --ldt-button-big-padding-hor: 1.6em;
        --ldt-button-transition-duration: 0.2s;
        --ldt-button-primary-color: var(--ldt-color-primary);
        --ldt-button-primary-outline-color: var(--ldt-color-primary);
        --ldt-button-primary-text-color: var(--ldt-color-negative);
        --ldt-button-primary-hover-color: var(--ldt-color-negative);
        --ldt-button-primary-hover-outline-color: var(--ldt-color-primary);
        --ldt-button-primary-hover-text-color: var(--ldt-color-primary);
        --ldt-button-secondary-color: var(--ldt-color-negative);
        --ldt-button-secondary-outline-color: var(--ldt-color-primary);
        --ldt-button-secondary-text-color: var(--ldt-color-primary);
        --ldt-button-secondary-hover-color: var(--ldt-color-primary);
        --ldt-button-secondary-hover-outline-color: var(--ldt-color-primary);
        --ldt-button-secondary-hover-text-color: var(--ldt-color-negative);
    }

    /* TEXT */
    :root {
        --ldt-h-large-font-size: 4em;
        --ldt-h-large-font-weight: 700;
        --ldt-h-medium-font-size: 3em;
        --ldt-h-medium-font-weight: 700;
        --ldt-h-regular-font-size: 2em;
        --ldt-h-regular-font-weight: 400;
    }

    /* SHADOWS */
    :root {
        --ldt-shadow: 0.25rem 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
        --ldt-shadow-lift: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
    }

    /* CARDS (possibly deprecated) */
    :root {
        --ldt-card-padding: 0.5rem;
        --ldt-card-border-radius: 1em;
        --ldt-card-shadow: var(--ldt-shadow);
        --ldt-card-transition-duration: 0.2s;
        --ldt-card-lifted-shadow: var(--ldt-shadow-lift);
        --ldt-card-lifted-transform: translateY(-0.2rem);
    }

    /* OTHERS */
    :root {
        --ldt-form-single-line-padding: 0.5em 1em;
    }
}
