/*
 * This files content is, and should always be, equal to node_modules/@marcreichel/angular-carousel/css/main.css.
 * It exists, because webpack will not import css files when they are not exported by the specifying package.
 *
 * If the package.json of https://github.com/marcreichel/angular-carousel has been updated correctly,
 * this file can be deleted and the import inside styles.scss updated!
 */

.carousel__slider {
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.carousel__previous,
.carousel__next {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 0.875rem;
    display: flex;
    align-items: center;
}

.carousel__next {
    left: auto;
    right: 0;
}

.carousel__previous button,
.carousel__next button {
    cursor: pointer;
    border: none;
    background: transparent;
    width: 2rem;
    height: 2rem;
    padding: 0;
}

.carousel__previous svg,
.carousel__next svg {
    width: 2rem;
    height: 2rem;
}

.carousel__slides {
    display: flex;
    position: relative;
    transition: left 300ms ease-in-out;
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-height: 3rem;
}

.carousel__slide {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    min-width: 100%;
    max-width: 100%;
    transition: opacity 300ms ease-in-out;
}

.carousel__slide:not(.carousel__slide--active) {
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: -1;
}

.carousel__bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: .625rem 0 0;
}

.carousel__bullets > li {
    padding: 0.25rem;
}

.carousel__bullets > li:empty {
    display: none;
}

.carousel__bullet {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.carousel__bullet.carousel__bullet--active {
    cursor: default;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
