.ag-theme-alpine {
    --ag-foreground-color: #666;
    --ag-header-foreground-color: #666;
    --ag-font-family: Poppins;
    --ag-font-weight: 400;
    --ag-font-size: 14px;

    --ag-borders: none;
    --ag-row-border-width: 0.5px;
    --ag-row-border-color: #ccc;

    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 80%;
    --ag-header-column-separator-width: 0.5px;
    --ag-header-column-separator-color: #ccc;

    --ag-header-column-resize-handle-width: 0px;

    --ag-odd-row-background-color: transparent;
}

.ag-theme-alpine .ag-header-cell {
    font-size: 11px;
}

.ag-body-viewport::-webkit-scrollbar {
    display: none;
}

.ag-full-width-container {
    overflow-x: auto;
    white-space: nowrap;
}

.ag-header-cell.no-after:after, .ag-header-cell.no-after:before {
    visibility: hidden;
}

.row-detail-title.row-detail-title.row-detail-title.row-detail-title {
    font-size: .6rem !important;
}

.row-detail-item {
    margin-bottom: .75rem;
}

.row-detail-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ag-cell-wrap-text {
    white-space: normal;
    word-break: break-all!important;
}

/*.detail-cell::after {
    content: "";
    width: 0.05rem;
    height: calc(100% - 0.5rem);
    position: absolute;
    right: 0;
    top: 0.25rem;
    background-color: #ccc;
}*/

.last-interactive-cell::after {
    content: "";
    width: 0.05rem;
    height: calc(100% - 0.5rem);
    position: absolute;
    right: 0;
    top: 0.25rem;
    background-color: #ccc;
}

.detail-cell-with-children::after {
    content: "";
    width: 0.05rem;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0.25rem;
    background-color: #ccc;
}

.detail-cell-child-container::after {
    content: "";
    width: 0.05rem;
    height: calc(100% - 0.5rem);
    position: absolute;
    right: 0;
    top: 0rem;
    background-color: #ccc;
}

.detail-cell-child-between-container::after {
    content: "";
    width: 0.05rem;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0rem;
    background-color: #ccc;
}

.full-width-row-left-space-container.hovered {
    background-color: transparent !important;
}

.full-width-row-left-space-container.detail-open {
    background-color: transparent !important;
}

.ag-full-width-row.hovered {
    background-color: transparent !important;
}

.ag-selection-checkbox.ag-selection-checkbox.ag-selection-checkbox {
    margin-right: 0;
}

/* disable padding for interactive cell column headers */
.ag-header-cell[col-id="rowDetails"],
.ag-header-cell[col-id="checkboxSelection"],
.ag-header-cell[col-id="actionOverflow"] {
    padding: 0;
    justify-content: center;
}

.ag-header-cell .ag-checkbox {
    margin: 0;
}

.ag-header-cell[col-id="checkboxSelection"] .ag-header-cell-comp-wrapper {
    display: none;
}

.interactive-cell {
    background-color: white;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
}

.interactive-cell.hovered {
    background-color: transparent;
}

.interactive-cell.detail-open {
    background-color: transparent !important;
}

.full-width-row-left-space-container.hovered {
    background-color: transparent !important;
}

.full-width-row-left-space-container.detail-open {
    background-color: transparent !important;
}

.ag-full-width-row.hovered {
    background-color: transparent !important;
}

.hidden-columns-overlay {
    border-radius: 0.15rem;
    padding: 0.9rem;
    background-color: #fff;
    border-width: 0.05rem;
    border-style: solid;
    border-color: #ccc;
    box-shadow: 0 0.05rem 0.15rem rgba(140,140,140,.25);
    width: 12.5rem;
    display: flex;
    flex-direction: column;
    z-index: 1050;
    position: fixed;
}

lib-ldt-dg-custom-cell-renderer a {
    color: var(--ag-data-color);
}

lib-ldt-dg-custom-cell-renderer a:link {
    color: var(--ag-data-color);
}

lib-ldt-dg-custom-cell-renderer a:hover {
    color: var(--ag-data-color);
}

lib-ldt-dg-custom-cell-renderer a:visited {
    color: var(--ag-data-color);
}

/* Dropdown Overflow Issue Fix */
.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}

.ag-root-wrapper, .ag-root.ag-layout-normal,
.ag-body-viewport.ag-layout-normal {
    overflow: visible;
}


.ag-center-cols-viewport.dropdown-overflow {
    overflow: visible;
}

.grid-footer-container {
    z-index: 0;
}

.pagination-container {
    z-index: 0;
    position: relative;
}
/* End Dropdown Overflow Issue Fix */

.custom-dashboard .button-footer-container .hidden-columns-button,
.custom-dashboard .button-footer-container .hidden-columns-reset-button,
.custom-dashboard .button-footer-container .storage-button {
    font-size: 10px !important;
    height: 1.75rem;
    width: 4.5rem;
    line-height: .65rem;
    margin: 0 12px 6px 0;
    padding: 0.15rem;
}

.custom-dashboard .primary-button-group .btn {
    font-size: 10px !important;
}

lib-ldt-datagrid .grid-wrapper lib-ldt-dg-special-filter .special-filter .special-filter-item,
lib-ldt-datagrid .grid-wrapper lib-ldt-dg-special-filter .special-filter .btn-group-overflow button cds-icon {
    color: var(--ldt-button-primary-color)!important;
}

/* Grid Container Queries: */
lib-ldt-datagrid .grid-wrapper {
    container-name: grid;
    container-type: inline-size;
}

@container grid (max-width: 1128px) {
    .grid-wrapper .button-container {
        justify-content: space-between;
    }

    .grid-wrapper lib-ldt-dg-special-filter .special-filter {
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: .3rem;
    }

    .grid-wrapper lib-ldt-dg-special-filter .special-filter .special-filter-item,
    .grid-wrapper lib-ldt-dg-special-filter .special-filter .dropdown-toggle {
        border-radius: 7.7px 0 0 7.7px !important;
        border-left: .05rem solid var(--ldt-button-primary-color) !important;
    }

    .grid-wrapper lib-ldt-dg-special-filter {
        justify-content: space-between;
    }

}

@container grid (max-width: 930px) {
    .grid-wrapper .grid-footer-container .button-footer-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

@container grid (max-width: 815px) {
    .grid-wrapper lib-ldt-dg-pagination .pagination-container {
        display: block !important;
    }

    .grid-wrapper lib-ldt-dg-pagination .pagination-container .pagination-top, .grid-wrapper lib-ldt-dg-pagination .pagination-container .pagination-bottom {
        display: flex;
        align-items: center;
    }

    .grid-wrapper lib-ldt-dg-pagination .pagination-container .pagination-bottom {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        align-content: flex-end;
    }
}

@container grid (max-width: 645px) {
    .grid-wrapper lib-ldt-dg-pagination .pagination-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

@container grid (max-width: 639px) {
    .grid-wrapper lib-ldt-dg-pagination .pagination-container .pagination-top {
        display: block !important;
    }
}

@container grid (max-width: 598px) {
    .grid-wrapper .button-container {
        flex-direction: column;
    }

    .grid-wrapper .button-container .primary-button-group {
        flex-direction: column;
        order: 2;
    }

    .grid-wrapper .button-container .primary-button-group.primary-button-group.primary-button-group .btn {
        margin-right: 0;
    }

    .grid-wrapper lib-ldt-dg-special-filter .special-filter .special-filter-item,
    .grid-wrapper lib-ldt-dg-special-filter .special-filter .dropdown-toggle {
        border-radius: 0 7.7px 7.7px 0 !important;
    }

    .grid-wrapper lib-ldt-dg-special-filter .special-filter {
        align-items: flex-start;
    }

    .button-container.button-container.button-container {
        align-items: flex-start;
    }
}

@container grid (max-width: 552px) {
    .grid-wrapper .grid-footer-container .button-footer-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}

@container grid (max-width: 455px) {
    .grid-wrapper .grid-footer-container .button-footer-container lib-ldt-dg-hidden-columns-button .hidden-columns-button-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
