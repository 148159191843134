/*
 * This file contains all styles intended for adjusting clarity components to match the corporate design
 */

:root {
    --clr-toggle-bg-color-on: var(--ldt-color-success);
    --clr-thead-bgcolor: var(--ldt-color-grey-300);

    --clr-datagrid-row-hover: transparent;
    --clr-forms-checkbox-background-color: var(--ldt-color-primary);
    --clr-nav-active-box-shadow-color: transparent;

    --clr-btn-appearance-form-line-height: 1rem;
    --clr-btn-border-width: var(--ldt-button-border-width);
    --clr-btn-border-radius: var(--ldt-button-border-radius);

    --clr-global-app-background: var(--ldt-color-negative);
    --clr-global-content-header-font-color: var(--ldt-color-font-primary);
    --clr-global-font: var(--ldt-font-secondary);
    --clr-global-font-color: var(--ldt-color-font-text);

    --clr-forms-label-color: var(--ldt-color-font-text);
    --clr-forms-text-color: var(--ldt-color-font-text);

    --clr-card-bg-color: var(--ldt-color-negative);
    --clr-card-title-font-size: var(--ldt-h-medium-font-size);
    --clr-card-title-font-weight: var(--ldt-h-medium-font-weight);
    --clr-card-title-color: var(--ldt-color-font-primary);
    --clr-card-border-bottom-color: var(--clr-card-border-color, #dedede);
    --clr-card-highlight-bg-color: var(--clr-card-bg-color, white);

    --clr-modal-bg-color: var(--ldt-color-negative);

    --clr-btn-primary-bg-color: var(--ldt-button-primary-color);
    --clr-btn-primary-border-color: var(--ldt-button-primary-outline-color);
    --clr-btn-primary-color: var(--ldt-button-primary-text-color);
    --clr-btn-primary-hover-bg-color: var(--ldt-button-primary-hover-color);
    --clr-btn-primary-hover-border-color: var(--ldt-button-primary-hover-outline-color);
    --clr-btn-primary-hover-color: var(--ldt-button-primary-hover-text-color);

    --clr-btn-default-outline-hover-bg-color: var(--ldt-button-secondary-hover-color);
    --clr-btn-default-outline-hover-color: var(--ldt-button-secondary-hover-text-color);

    --clr-btn-default-outline-border-color: var(--ldt-button-secondary-outline-color);
    --clr-btn-default-outline-color: var(--ldt-button-secondary-text-color);

    --clr-btn-default-bg-color: var(--ldt-color-negative);
    --clr-btn-default-border-color: var(--ldt-color-primary);
    --clr-btn-default-color: var(--ldt-color-primary);
    --clr-btn-default-hover-bg-color: var(--ldt-color-primary);
    --clr-btn-default-hover-border-color: var(--ldt-color-primary);
    --clr-btn-default-hover-color: var(--ldt-color-negative);

    //--clr-btn-link-bg-color: var(transparent);
    --clr-btn-link-color: var(--ldt-color-font-primary);
    //--clr-btn-link-border-color: var(--ldt-color-primary);
    //--clr-btn-link-hover-bg-color: var(--ldt-color-primary);
    --clr-btn-link-hover-color: var(--ldt-color-primary);
    //--clr-btn-link-hover-border-color: var(--ldt-color-primary);

    --clr-font: var(--ldt-font-secondary);
    --clr-display-font: var(--ldt-font-primary);
    --clr-h1-font-weight: 700;
    --clr-h2-font-weight: 500;
    --clr-h3-font-weight: 500;
}

//hotfix for btn group having white borders, should be removed as it overrides theming variables
.btn {
    border-width: 0.05rem;
}

.btn, .btn label {
    --transition-duration: 0.2s;
    text-transform: none;
    font-family: var(--ldt-button-font-family);
    font-size: var(--ldt-button-font-size);
    font-weight: var(--ldt-button-font-weight);
    letter-spacing: inherit;
    cursor: pointer;
    transition-duration: var(--transition-duration);

    cds-icon {
        transition-duration: calc(var(--transition-duration) * 0.25);
    }
}

.btn.btn.btn-link {
    border-radius: 0; //override border-radius on .btn-link, as it has no visible body

    &:hover cds-icon, cds-icon {
        color: var(--clr-btn-link-hover-color) !important;
    }
}

// Style checked radio buttons
.radio.btn input[type="radio"]:checked + label, .radio.btn input[type="radio"]:checked + label {
    background-color: var(--ldt-button-primary-color);
    color: var(--ldt-button-primary-text-color);

    cds-icon, cds-icon {
        color: var(--ldt-button-primary-text-color);
    }
}

// Style checked primary radio buttons
.radio.btn.btn-primary input[type="radio"]:checked + label, .radio.btn.btn-primary input[type="radio"]:checked + label, .btn.btn-primary:hover {
    background-color: var(--ldt-button-secondary-color);
    color: var(--ldt-button-secondary-text-color);

    cds-icon, cds-icon {
        color: var(--ldt-button-secondary-text-color);
    }
}

/* Disable focus outline on radio buttons for now. visible-focus outline should be implemented in the future though */
.btn-group .btn input[type=radio]:focus, .btn-group .btn input[type=checkbox]:focus {
    border-style: none;
}

.btn:hover {
    --clr-btn-default-outline-color: var(--clr-btn-default-outline-hover-color);
}

a:not(.btn) {
    color: var(--ldt-color-primary);
}

.btn.btn-dirty {
    background-color: var(--ldt-button-dirty-background-color);
}

.btn.btn-dirty:hover {
    color: var(--ldt-button-primary-hover-color);
}

//adjust tab background color (name is irritating)
.btn-secondary.btn-secondary {
    background-color: var(--ldt-color-primary-300);
    //background-color: var(--quickfix-highlight-color);
    color: var(--ldt-color-primary--100);
    letter-spacing: normal;
}

//edit form style
.clr-input-wrapper.clr-input-wrapper, .clr-select-wrapper.clr-select-wrapper, .clr-textarea-wrapper.clr-textarea-wrapper {
    max-height: none;
    font-size: 0.8rem;

    .clr-select {
        font-size: inherit;
    }

    .clr-validate-icon {
        display: none;
    }
}

.clr-input {
    font-size: inherit;
}

.clr-input-group.clr-input-group.clr-input-group {
    margin-right: 0;
    background-color: var(--ldt-color-negative-highlight);
    padding: var(--ldt-button-padding);
    box-sizing: border-box;
    height: auto;
    border-radius: var(--ldt-button-border-radius);
    border-width: 0.1em;
    border-color: transparent;
    display: flex;
    flex-wrap: nowrap;

    .clr-input {
        padding: 0;
        width: 100%;
        max-width: none;
        color: var(--ldt-color-font-primary);
        flex-grow: 1;
    }

    .clr-input-group-icon-action {
        display: inline-block;
        padding: 0;
        color: var(--ldt-color-font-primary);
        cursor: pointer;

        cds-icon {
            --color: var(--ldt-color-font-primary);
        }
    }
}

.clr-textarea-wrapper {
    margin: 0;
}

.clr-input-wrapper > .clr-input.clr-input.clr-input,
.clr-select-wrapper > .clr-select.clr-select.clr-select,
.clr-textarea-wrapper > .clr-textarea.clr-textarea.clr-textarea {
    background-color: var(--ldt-color-negative-highlight);
    padding: var(--ldt-button-padding);
    box-sizing: border-box;
    max-height: none;
    height: auto;
    border-radius: var(--ldt-button-border-radius);
    border-width: 0.1em;
    border-color: transparent;
    outline: none;
    box-shadow: none;
    max-width: none;
}

.clr-input-wrapper > .clr-input.clr-input.clr-input:disabled,
.clr-select-wrapper > .clr-select.clr-select.clr-select:disabled,
.clr-textarea-wrapper > .clr-textarea.clr-textarea.clr-textarea:disabled {
    background-color: var(--ldt-color-negative-highlight-disabled);
    color: var(--ldt-color-grey);
}

//remove focus outline on some control-label-elements like the switch
.clr-control-label.clr-control-label.clr-control-label.clr-control-label::before {
    outline: none;
}

.clr-control-label {
    user-select: none;
}

.clr-error .clr-input-group.clr-input-group.clr-input-group,
.clr-error .clr-input.clr-input.clr-input,
.clr-error .clr-select.clr-select.clr-select,
.clr-error .clr-textarea.clr-textarea.clr-textarea {
    // Set error border style
    border-color: var(--ldt-color-danger);
}

.clr-input-group.clr-input-group.clr-input-group.clr-input-group,
.clr-input.clr-input.clr-input.clr-input,
.clr-select.clr-select.clr-select.clr-select,
.clr-textarea.clr-textarea.clr-textarea.clr-textarea{
    // Disable default clarity input indicator bar
    background-size: 0 0;
    border-style: solid;

    &:focus-visible, &:focus-within {
        border-color: var(--ldt-color-primary);
    }
}

.clr-timeline svg {
    --clr-timeline-current-step-color: var(--ldt-color-primary) !important;
    --clr-timeline-success-step-color: var(--ldt-color-success) !important;
}

.clr-form-control, .search-wrapper {
    margin: 0 0 0.6rem 0;
}

cds-icon {
    &[status="success"] {
        --color: var(--ldt-color-success--100);
    }
    &[status="info"] {
        --color: var(--ldt-color-primary--100);
    }
}

.clr-timeline-step-body.clr-timeline-step-body.clr-timeline-step-body::before {
    background-color: var(--ldt-color-primary-300);
}
