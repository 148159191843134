@import "styles/fallback-branding-styles";
@import "styles/fonts";
@import "styles/migrate-ag-grid-to-corporate-design";
@import "styles/migrate-clarity-to-corporate-design";

/* import styles required for angular-carousel */
@import "styles/angular-carousel-main.css";

/* import styles required for cdk overlays */
@import '@angular/cdk/overlay-prebuilt.css';

/* import styles required for ag-grid */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/* Deprecated global variables, use the ones defined inside styles/fallback-branding-styles instead */
:root {
    --primary-color: var(--ldt-color-primary, #1EBBD7);
    --secondary-color: var(--ldt-color-secondary, #107dac);
    --third-color: #005073;
    --fourth-color: #BFBAA8;
    --fifth-color: #EBEBEB;
    --hover-color: #1ebbd7;
    --primary-color-filter: invert(83%) sepia(28%) saturate(7308%) hue-rotate(144deg) brightness(92%) contrast(82%);
    --nav-background: #ffffff;
    --nav-font-color: #000000;

    // login page
    --ldt-um-login-page-background: url(/assets/img/banner_landing.jpg) bottom no-repeat;
    --ldt-um-login-page-form-background: #fff;

    --cds-global-base: 24;

    // froala font color
    --froala-default-font-color: var(--clr-global-font-color, #000);
}

/* ------------------------------------------ */
/* M I X I N S */

@mixin for-phone-screens-and-down {
    @media (max-width: 786px) { @content }
}

@mixin for-above-phone-screens {
    @media (min-width: 787px) { @content }
}

/* ------------------------------------------ */

body {
    background: var(--clr-global-app-background, #fff);
    //position: relative;
}


/* fill svg graphics */
.svg-fill-primary {
    fill: var(--color-primary) !important;
}

.svg-fill-secondary {
    fill: var(--ldt-color-secondary) !important;
}

/* invert img graphics */
/* generator: https://codepen.io/sosuke/pen/Pjoqqp */
.img-filter-primary {
    filter: var(--ldt-color-primary-filter);
}

.img-filter-white {
    filter: invert(100%) sepia(13%) saturate(7447%) hue-rotate(200deg) brightness(114%) contrast(114%);
}

/* set default font color for editor */
.editor, .editor p {
    color: #000000 !important;
}

/* padding /margin options*/

.no-padding {
    padding: 0;
}

.padding-lg {
    padding: 2.6rem;
}

.padding-top-md {
    padding-top: 0.6rem;
}

.padding-top-lg {
    padding-top: 2.6rem;
}

.padding-bottom-sm {
    padding-bottom: 0.3rem;
}

.padding-bottom-md {
    padding-bottom: 0.6rem;
}

.padding-bottom-lg {
    padding-bottom: 2.6rem;
}

.padding-horizontal-sm {
    padding: 0 0.3rem 0 0.3rem;
}

.padding-horizontal-md {
    padding: 0 0.6rem 0 0.6rem;
}

.padding-horizontal-lg {
    padding: 0 2.6rem 0 2.6rem;
}

.padding-vertical-sm {
    padding: 0.3rem 0 0.3rem 0;
}

.padding-vertical-md {
    padding: 0.6rem 0 0.6rem 0;
}

.padding-vertical-lg {
    padding: 2.6rem 0 2.6rem 0;
}

.no-margin {
    margin: 0;
}

.margin-sm {
    margin: 0.3rem !important;
}

.margin-md {
    margin: 0.6rem !important;
}

.margin-lg {
    margin: 2.6rem !important;
}

.margin-top-sm {
    margin-top: 0.3rem !important;
}

.margin-top-md {
    margin-top: 0.6rem !important;
}

.margin-top-lg {
    margin-top: 2.6rem !important;
}

.margin-bottom-sm {
    margin-bottom: 0.3rem;
}

.margin-bottom-md {
    margin-bottom: 0.6rem;
}

.margin-bottom-lg {
    margin-bottom: 2.6rem;
}

.margin-horizontal-sm {
    margin: 0 0.3rem 0 0.3rem;
}

.margin-horizontal-md {
    margin: 0 0.6rem 0 0.6rem;
}

.margin-horizontal-lg {
    margin: 0 2.6rem 0 2.6rem;
}

.margin-vertical-sm {
    margin: 0.3rem 0 0.3rem 0;
}

.margin-vertical-md {
    margin: 0.6rem 0 0.6rem 0;
}

.margin-vertical-lg {
    margin: 2.6rem 0 2.6rem 0;
}

.font-small {
    font-size: 0.6rem;
}

.font-bold {
    font-weight: bold;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.centered-text {
    text-align: center;
    width: 100%;
    max-width: 100%;
}

.right {
    text-align: right;
    margin-left: auto;
    margin-right: 0;
}

.inline {
    display: inline-block;
}

/* bpmn-io form-js modal for workflow editor */
#form-editor-wrapper {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.4rem;
    background-color: rgba(51, 51, 51, 0.85);
    z-index: 1040;
}

#form-editor-wrapper-lg {
    width: 50rem;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

#form-editor {
    z-index: 1050;
    padding: 1.2rem 1.2rem 1.2rem 1.2rem;
    background-color: #fff;
    background-color: var(--clr-modal-bg-color, white);
    border-radius: 0.15rem;
    border-radius: var(--clr-modal-border-radius, 0.15rem);
    box-shadow: 0 0.05rem 0.1rem 0.1rem rgb(0 0 0 / 20%);
    box-shadow: 0 0.05rem 0.1rem 0.1rem var(--clr-modal-content-box-shadow-color);
}

/* tree view arrow icon */

clr-tree cds-icon {
    color: var(--primary-color)
}

/* Fixed horizontal scroll issue */
.clr-row {
    margin: 0;
}

.row-no-padding {
    padding: 0;
}

.clr-control-container {
    padding-left: 0;
}

.clr-form-control {
    margin-bottom: 1rem;
    padding-left: 0;
}

.clr-input {
    width: 100%;
}

.clr-focus {
    width: 100% !important;
}

.clr-form-control-disabled {
    align-items: normal;
}

.clr-input-group {
    height: 26px;
    max-width: 100%;
    width: 100%;
}

.clr-input-wrapper {
    max-width: 100% !important;
}

.toggle-switch {
    height: auto;
}

.clr-input-space {
    margin-right: .5rem;
}

.clr-error .clr-input-group {
    margin-right: 35px;
}

.clr-form {
    padding: 0;
}

.modal-footer .btn-group .btn {
    margin: 0;
}

/* Card customizing */

.card {
    box-shadow: 7px 7px 12px 0 rgba(0, 0, 0, 0.19);
}

.card-header, .card-block {
    border-bottom-color: var(--clr-card-border-bottom-color, #dedede);
}

// card with different color
.card-highlight {
    background-color: var(--clr-card-highlight-bg-color, white);
}

/* Fullsize Textarea */
.clr-control-container {
    width: 100%;
}

.clr-textarea {
    width: 100% !important;
}

clr-password-container .clr-input-group {
    width: 100%;
    max-width: 100%;
}


/* radio button as card */

.img-radio input {
    cursor: pointer;
    /* to get tabindex working */
    /*display: none;
    visibility: hidden;*/
    opacity: 0;
}

.img-radio .clr-control-label, .img-checkbox .clr-control-label {
    width: 160px;
    padding: 0;
    margin: 10px;
}

.card.clickable::before {
    visibility: hidden;
}

/* checkbox as card with arrow button */

.card.clickable.with-button::before {
    visibility: visible !important;
    top: 0.3rem !important;
    left: 0.3rem !important;
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.card.clickable.with-button::after {
    visibility: visible !important;
    top: 0.3rem !important;
    left: 0.45rem !important;
    width: 0.9rem !important;
    height: 0.5rem !important;
}

.img-radio [type=radio]:checked + label, .img-checkbox [type=checkbox]:checked + label, .img-radio [type=radio]:hover + label, .img-checkbox [type=checkbox]:hover + label {
    @extend %clickable-card-transition;
}

.img-radio [type=radio] + label, .img-checkbox [type=checkbox] + label {
    @extend %clickable-card;
}

.img-radio .clr-control-inline, .img-checkbox .clr-control-inline {
    justify-content: center;
}

.img-radio .clr-control-label, .img-checkbox .clr-control-label {
    text-align: center;
}

.card-img + .card-block {
    padding-left: 0;
    padding-right: 0;
}

/** radiobutton card with two row body */
.card-two-row .card-block {
    height: 72px;
}

/* to disable the up and down arrows in number inputs */
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .img-radio .clr-control-label, .img-checkbox .clr-control-label {
        width: 135px;
    }
}

/* NG-SELECT CUSTOM STYLE */

.ng-select.custom .ng-select-container {
    border: none;
    border-bottom: 1px solid #999999;
    border-radius: 0;
    height: auto !important;
    min-height: 0 !important;
    padding: .125rem 0;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 0 !important;
}

clr-select-container.no-dropdown-arrow label.clr-control-label {
    z-index: 110;
}

.clr-select-wrapper > select.clr-select {
    width: 100%;
}

clr-select-container.no-dropdown-arrow .clr-select-wrapper::after {
    content: none;
}

clr-select-container.no-dropdown-arrow .clr-select-wrapper, .clr-select-wrapper {
    width: 100%;
    display: inherit;
    max-height: none;
}

div.clr-flex-nowrap {
    margin-top: -1rem;
}

/* NGX-QUILL CUSTOM STYLE */

.ql-container {
    min-height: 250px;
}

.ngx-quill-view div {
    padding: 20px 0 20px 0;
}

.color-white, .color-white p {
    color: #ffffff !important;
}


/* floating label directive style */
.floating-label {
    position: relative;
    transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    user-select: none;
    pointer-events: none;
    transform-origin: left;
    max-width: 90%;
    //z-index: 3;
}

.floating-label-required {
    left: 1em;

    &.floating-label-focused {
        left: 0;

        &::before {
            left: 0;
            opacity: 0;
        }
    }

    &::before {
        border-radius: 0.1em;
        content: '';
        position: absolute;
        top: 0.25em;
        bottom: 0.25em;
        left: -0.75em;
        width: 0.2em;
        background-color: var(--ldt-color-danger);
    }
}

.floating-label:not(.floating-label-focused) {
    max-width: 90%;
    transform: translateY(calc(1.8em + var(--ldt-button-padding-ver))) translateX(var(--ldt-button-padding-hor)) scale(1.1);
    font-weight: 500;
    z-index: 110;
}


ng-select.custom {
    font-size: .75rem;
}

.pre-wrap {
    white-space: pre-wrap;
}

/* bigger radiobuttons */
.clr-radio-wrapper input[type=radio] + label::before {
    top: .2083rem;
    width: .75rem;
    height: .75rem;
}

input[type=radio] + label {
    /* font-size: 0.6667rem; Disabled: makes radio buttons differ from standard buttons */
    /* line-height: 1.25rem; Disabled: makes radio buttons differ from standard buttons */
}

.btn.btn input + label {
    line-height: 2em; /* Centers text on buttons with label structure */
}
a.btn {
    line-height: 2.5em;
}

.clr-checkbox-wrapper input[type=checkbox] + label::before {
    top: .2083rem;
    width: .75rem;
    height: .75rem;
}

input[type=checkbox] + label {
    font-size: 0.6667rem;
    line-height: 1.25rem;
}

.clr-checkbox-wrapper input[type=checkbox]:checked + label::after {
    top: 0.25rem;
    left: 0.2083rem;
}

.clr-control-container textarea {
    font-size: 0.75rem;
}


/* datagrid select pagination fix */
.datagrid-footer .pagination-size .clr-select-wrapper::after {
    top: 0.1rem;
}


/* fix for IE */
clr-password-container {
    display: block !important;
}

/* fix for IE for selectable card-img*/
.card-img > img {
    pointer-events: none;
}

/* fix for IE for datagrid pagination*/
.pagination-list .pagination-previous, .pagination-list .pagination-next {
    height: 100%;
    width: 100%;
}

/* --- */

.btn.btn.btn-highlight {
    height: auto;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    white-space: normal;
    box-shadow: 7px 7px 12px 0 rgba(0, 0, 0, 0.19);
}

.btn.btn-big {
    height: auto;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    white-space: normal;
    letter-spacing: 0.075rem;
}

.btn.btn-full {
    width: 100%;
    max-width: 100%;
}

.btn.btn-big.btn-primary {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
}

.btn-group-responsive {
    display: block !important;
}

.btn-group-big {
    width: 100% !important;
}

.btn-no-margin {
    margin: 0 !important;
}

.btn-group *:not(:first-child) .btn-group-child {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-big .dropdown-toggle {
    height: 100%;
}

/* clarity card - has no color variable */
%clickable-card-transition {
    box-shadow: 0 0.125rem 0 0 var(--ldt-button-primary-color);
    border-color: var(--ldt-button-primary-color);
}

%clickable-card {
    --clr-forms-checkbox-background-color: var(--ldt-button-primary-color);
}

.btn.btn-secondary {
    border: var(--fifth-color);
    background-color: var(--fifth-color);

    &:hover {
        background-color: var(--fifth-color);
        color: var(--primary-color);
        box-shadow: 0 -0.125rem 0 var(--primary-color) inset;
        transition: box-shadow 0.2s ease-in;
    }

    &:active {
        box-shadow: 0 -0.125rem 0 var(--primary-color) inset;
    }
}

.btn.btn-success-secondary {
    color: white;
    border-color: var(--clr-color-success-500, #5AA220FF);
    background-color: var(--clr-color-success-500, #5AA220FF);

    &:hover {
        background-color: var(--clr-color-success-700, #3C8500FF);
        transition: background-color 0.1s ease-in;
    }

    &:active {
        box-shadow: 0 -0.125rem 0 var(--clr-color-success-700, #3C8500FF) inset;
    }
}

.btn.btn-warning-secondary {
    color: white;
    border-color: var(--clr-color-danger-700, #DB2100FF);
    background-color: var(--clr-color-danger-700, #DB2100FF);

    &:hover {
        background-color: var(--clr-color-danger-900, #991700FF);
        transition: background-color 0.1s ease-in;
    }

    &:active {
        box-shadow: 0 -0.125rem 0 var(--clr-color-danger-900, #991700FF) inset;
    }
}

.nav .nav-items {
    width: 100%;
    margin-right: 0;
}

// custom clr-tabs
.nav {
    height: 3rem;
    box-shadow: none;
    align-items: flex-start !important;

    .nav-link.active, .nav-link.nav-link:hover {
        background-color: var(--primary-color);
        color: white;
    }

    .nav-item {
        width: 100%;
        margin-right: .25rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

@media (max-width: 768px) {
    .nav .nav-link.btn {
        height: 6vh;
        padding-bottom: 40px;
        padding-top: 5px;
    }
}

// custom tabs-overflow
.tabs-overflow-small {
    .tabs-overflow {
        width: 25%;
        display: inline-block;
        margin-left: 0.25rem;

        .btn-link {
            border: var(--fifth-color);
            background-color: var(--fifth-color);
            width: 100%;
            max-width: 100%;
            height: auto;
            font-size: 0.9rem;
            white-space: normal;
            letter-spacing: 0.075rem;

            .is-info {
                fill: white;
            }

            &.open {
                background-color: var(--primary-color);
            }
        }

        .dropdown-menu {
            padding: 0;

            .btn {
                border-radius: 0;
                margin: 0 0 5px 0;

                &.active {
                    background-color: var(--primary-color);
                    color: #ffffff;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.tabs-overflow-big {
    .tabs-overflow {
        width: 25%;
        display: inline-block;
        margin-left: 0.25rem;

        .btn-link {
            border: var(--fifth-color);
            background-color: var(--fifth-color);
            width: 100%;
            max-width: 100%;
            height: auto;
            padding: 0.5rem 1rem;
            font-size: 0.9rem;
            white-space: normal;
            letter-spacing: 0.075rem;

            .is-info {
                fill: white;
            }

            &.open {
                background-color: var(--primary-color);
            }
        }

        .dropdown-menu {
            padding: 0;

            .btn {
                border-radius: 0;
                margin: 0 0 5px 0;

                &.active {
                    background-color: var(--primary-color);
                    color: #ffffff;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .tabs-overflow {
        width: 100%;
        margin-left: 0rem;
    }
}

// custom datagrid
.custom .datagrid {
    border: none;

    .datagrid-table {
        position: static;
        min-height: 0;
    }

    .datagrid-cell {
        width: 100%;
        font-size: 0.75rem;
        line-height: 1rem;
        margin: .1rem 0;

        p {
            font-size: 0.6rem;
        }
    }
}

clr-datagrid {
    // variable length for expandable datagrid
    height: auto !important;
}

.custom .datagrid-footer {
    border: none;
    background-color: unset;

    .datagrid-footer-description {
        margin-right: 1rem;
        line-height: 1.5rem;
    }
}

.datepicker {
    z-index: 5000;
}


@media screen and (max-width: 768px) {
    .nav {
        display: contents;

        .nav-item {
            display: block;
            margin-top: 0.1rem;
        }
    }
}

/* Internet Explorer 11 Fix */
.tab-content {
    display: inline !important;
}


// generic styles
.clickable {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.margin-top {
    margin-top: 1rem;
}

.margin-auto {
    margin: auto !important;
}

.title {
    font-size: 1.5rem;
}

.subtitle {
    margin: 0;
    line-height: 1.6em;
    font-size: 1.6rem;
    color: #ffffff;
    text-shadow: 0 0 8px var(--primary-color);
}

.banner-title {
    font-size: 3rem;
    line-height: 3rem;
    color: #ffffff;
    text-shadow: 0 0 5px var(--primary-color);
}

.sticky-top {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 999;
}

.bg-white {
    background: white;
}

.bg-yellow {
    background: var(--clr-color-warning-100, #FFF4C7FF);

    &:hover {
        background: var(--clr-color-warning-100, #FFF4C7FF);
    }
}

.bg-blue {
    background: var(--clr-color-action-100, #C8EAF9FF);

    &:hover {
        background: var(--clr-color-action-100, #C8EAF9FF);
    }
}

.bg-red {
    background: var(--clr-color-danger-100, #FEDDD7FF);

    &:hover {
        background: var(--clr-color-danger-100, #FEDDD7FF);
    }
}

.bg-green {
    background: var(--clr-color-success-100, #BCE49AFF);

    &:hover {
        background: var(--clr-color-success-100, #BCE49AFF);
    }
}

.bg-purple {
    background: var(--clr-color-secundary-action-100, #F7E5FF);

    &:hover {
        background: var(--clr-color-secundary-action-100, #F7E5FF);
    }
}

.bg-orange {
    background: var(--clr-color-secundary-action-100, rgba(250, 74, 0, 0.61));

    &:hover {
        background: var(--clr-color-secundary-action-100, rgba(250, 74, 0, 0.61));
    }
}

.bg-grey {
    background: var(--clr-color-neutral-300, #dedede);

    &:hover {
        background: var(--clr-color-neutral-300, #dedede);
    }
}

/** PDF viwer custom style **/
.below770px {
    min-width: 320px;
}

.ng2-pdf-viewer-container {
    height: 650px !important;
    width: var(--pdf-viewer-width, 100%) !important;
}

@media screen and (max-width: 1300px) {
    .ng2-pdf-viewer-container {
        height: 470px !important;
    }
}

/** Froala editor custom style **/
.fr-popup {
    z-index: 1200 !important;
}

// set default color
.fr-box, .fr-box p, .fr-box ul .fr-box li, .fr-box div, .fr-box h1, .fr-box h2, .fr-box h3, .fr-box h4, .fr-box h5, .fr-box h6 {
    color: var(--froala-default-font-color, #000) !important;
}

// inline Pdf Viewer Highlight
span > .highlight {
    background-color: #e2ff00 !important;
}

//Change ng-select color
.ng-select span {
    color: var(--ldt-color-font-text);
}

/* ------------------------------------------
 * BPMN-JS CUSTOMIZING
 * ------------------------------------------ */

.set-animation-speed {
    bottom: 60px!important;
}

/* ------------------------------------------
 * BPMN-JS CUSTOMIZING END
 * ------------------------------------------ */

.ng-select-container.ng-select-container.ng-select-container.ng-select-container.ng-select-container {
    background: none;
    padding: 0;
    border-bottom: none;
    box-shadow: none;
}

//fix unnecessary ng-value-container padding resulting in input size variations
.ng-value-container.ng-value-container.ng-value-container.ng-value-container.ng-value-container, .ng-value-container > .ng-input.ng-input.ng-input.ng-input.ng-input {
    padding: 0;
}

button:focus {
    outline: none;
}

.datagrid-table .datagrid-cell:focus {
    outline: none;
}

/* Form Positioning */
.form-container {
    height: 100%;
    display: flex;
}

.form-child {
    margin: 5vw auto;
    width: 60em;
}

/* Make clr-select font-size smaller under datagrid */
.datagrid-footer .pagination-size .clr-page-size-select {
    font-size: 0.55rem;
}

/* Fixes button text not being aligned properly, dont know why */
:root {
    --clr-btn-appearance-form-line-height: 1em;
}

/* Set card font family manually, as there is no clarity variable */
.card-header, .card-title {
    font-family: var(--ldt-font-primary);
}

/* Make Workflow-divider visible again */
lib-work-grid .as-horizontal.as-horizontal.as-horizontal > .as-split-gutter {
    height: auto;
}

@media screen and (min-width: 786px) {
    ::-webkit-scrollbar {
        width: 0.8rem;
        background: #00000011;
    }

    ::-webkit-scrollbar-thumb {
        background: #00000077;
        border-radius: 0.4rem;
        border: 0.2rem solid transparent;
        background-clip: content-box;
    }
}

/** DATAGRID UPSCROLL WORKAROUND **/
.datagrid-action-overflow {
    // Fixes upscrolling in datagrid
    position: fixed;
}
