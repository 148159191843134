/* ------------------------------------------
 * Font Face Declarations
 * ------------------------------------------ */

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.eot');
    src: url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.woff') format('woff'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.eot');
    src: url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.eot') format('embedded-opentype'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.woff2') format('woff2'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.woff') format('woff'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.eot');
    src: url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.eot') format('embedded-opentype'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.woff') format('woff'),
    url('/assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/poppins/Poppins-Regular.eot');
    src: url('/assets/fonts/poppins/Poppins-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/poppins/Poppins-Medium.eot');
    src: url('/assets/fonts/poppins/Poppins-Medium.eot') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/poppins/Poppins-SemiBold.eot');
    src: url('/assets/fonts/poppins/Poppins-SemiBold.eot') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* ------------------------------------------
 * Legacy Font Face Declarations
 * ------------------------------------------ */

@font-face {
    font-family: Poppins-Medium;
    src: url('/assets/fonts/poppins/Poppins-Medium.eot');
    src: local('Poppings Medium'), local('Poppins-Medium'),
    url('/assets/fonts/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Poppins-Regular;
    src: url('/assets/fonts/poppins/Poppins-Regular.eot');
    src: local('Poppings Regular'), local('Poppins-Regular'),
    url('/assets/fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
    url('/assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
